import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/Seo';

const IndexPage = () => (
  <Layout fullMenu subPage>
    <Seo
      title='Skútr servis Praha'
      keywords='scooter, skutr, servis Praha'
      description='skutr servis, scooter servis, opravy skutru v praze'
    />
    <div id='main'>
      <div className='frame'>
        <div className='frame_col mr20'>
          &nbsp;
        </div>
        <div id='bottom'>
          <div id='content' style={{ textAlign: 'center' }}><br /><br /><br />
            <h2>KONTAKT</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <strong>Příjem oprav po telefonické dohodě</strong>
            <h2 className='cleaner'>Černokostelecká Praha 10</h2>
            <h2 className='cleaner'><span>tel: 775 504 541</span>
            </h2>
            <h2><span>scooter83@seznam.cz</span></h2>
            <h2 className='cleaner'><span>Po - Čt </span><span
            >10:00 - 13:00&nbsp; 14:00 - 19:00</span></h2>
            <strong>Pátek 10:00 - 18:00</strong>
           <br />
           <br />
           <br />
           <br />
            <h2><em>V případě Vašeho zájmu nás prosím předem
              kontaktujte</em></h2>
            <br />
            <br />
          </div>
          <div className='cleaner' />
          <br /><br />
        </div>
        <div className='cleaner' />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
